import React, { useEffect, useState, useRef } from 'react';
import { withRouter } from 'react-router-dom';

import { toast, confirm } from '@rickylandino/react-messages';
import NumberFormat from 'react-number-format';
import Moment from 'moment';

import Card from 'react-bootstrap/Card';
import '../../../css/card.css';

import { Empty, message, Select } from 'antd';

import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';

import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { getAuditResults, getAuditSearchInformation, getAuditSearchResults } from '../../../common/services/AuditService';
import { exportAuditList } from '../../../common/services/ApiService';

import ReactLoading from 'react-loading';

import { useForm, Controller } from "react-hook-form";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Globals from '../../../config/globals';

const { Option } = Select;

function ViewAuditHistory(props) {

    const [state, setState] = useState({
        auditInfo: [],
        loaded: false
    });

    const [searchItems, setSearchItems] = useState({
        eventTypes: [],
        users: []
    });

    const [selectedEventTypes, setSelectedEventTypes] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);

    const [selectedFacilities, setSelectedFacilities] = useState([]);

    const [loading, setLoading] = useState(false);

    var searchString = useRef("");

    const { register, handleSubmit, setValue, getValues, watch, control } = useForm();

    const eventTimeStart = watch("eventTimeStart", props.drivers);
    const eventTimeEnd = watch("eventTimeEnd", props.drivers);

    const examTimeStart = watch("examTimeStart", props.drivers);
    const examTimeEnd = watch("examTimeEnd", props.drivers);

    const resultTimeStart = watch("resultTimeStart", props.drivers);
    const resultTimeEnd = watch("resultTimeEnd", props.drivers);

    useEffect(() => {
        var alive = true;

        getAuditSearchInformation().then(data => {
            setSearchItems({
                eventTypes: data.eventTypes,
                users: data.users
            });
        });

        //getAuditResults().then(data => {
        //    if (alive) {
        //        setState({
        //            auditInfo: data,
        //            loaded: true
        //        });
        //    }
        //});

        return () => { alive = false };
    }, []);

    function handleAuditExport() {
        const key = "loading";
        message.loading({ content: 'Exporting Audit Results...', key, duration: 0 });
        let fileName = "audit-results_" + Moment().format('YYYYMMDDHm') + '.xlsx';

        var filteredresults = state.auditInfo;

        let searchQuery = searchString.current;

        if (searchQuery) {
            filteredresults = state.auditInfo.filter((item) => {
                if (item.userName?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.eventType?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    item.mrn?.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    (item.eventTime && Moment(new Date(item.eventTime)).utc().format('lll')?.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (item.examDateTime && Moment(new Date(item.examDateTime)).utc().format('lll')?.toLowerCase().includes(searchQuery.toLowerCase())) ||
                    (item.resultDate && Moment(new Date(item.resultDate)).utc().format('lll')?.toLowerCase().includes(searchQuery.toLowerCase()))
                ) {
                    return item;
                }
            });
        }

        let postdata = {
            results: filteredresults,
            searchCriteria: searchQuery
        }

        exportAuditList(postdata).then(data => {
            const url = window.URL.createObjectURL(new Blob([data]));
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            // the filename you want
            a.download = fileName;//"File.xlsx";
            //document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);

            message.destroy(key);
        }).catch(error => {
            console.log("Error");
        });
    }

    function handleSearch() {
        setLoading(true);

        let postdata = {
            eventTimeStart,
            eventTimeEnd,
            examTimeStart,
            examTimeEnd,
            resultTimeStart,
            resultTimeEnd,
            selectedEventTypes,
            selectedUsers,
            selectedFacilities,
            mrn: getValues().mrn
        };
        getAuditSearchResults(postdata).then(data => {
            setLoading(false);
            setState({
                ...state,
                auditInfo: data,
                loaded: true
            });
        });
        
    }

    const columns = [{
        dataField: 'eventTime',
        text: 'Event Date/Time',
        formatter: cell => Moment(new Date(cell)).utc().format('lll'),
        filterValue: cell => Moment(new Date(cell)).utc().format('lll'),
    }, {
        dataField: 'userName',
        text: 'User Name'
    }, {
        dataField: 'eventType',
        text: 'Event Type'
    }, {
        dataField: 'mrn',
        text: 'MRN'
    }, {
        dataField: 'facilityName',
        text: 'Facility'
    }, {
        dataField: 'examDateTime',
        text: 'Exam Date/Time',
        formatter: cell => cell && Moment(new Date(cell)).utc().format('lll'),
        filterValue: cell => cell && Moment(new Date(cell)).utc().format('lll'),
    }, {
        dataField: 'resultDate',
        text: 'Result Date/Time',
        formatter: cell => cell && Moment(new Date(cell)).utc().format('lll'),
        filterValue: cell => cell && Moment(new Date(cell)).utc().format('lll'),
    }];

    const selectRow = {
        mode: 'radio',
        clickToSelect: true,
        hideSelectColumn: true
    };

    const rowEvents = {
        onClick: (e, row, rowIndex) => {
        }
    };

    const sizePerPageOptionRenderer = ({
        text,
        page,
        onSizePerPageChange
    }) => (
        <li
            key={text}
            role="presentation"
            className="form-control-custom hover"
            tabIndex="-1"
            data-page={page}
            onMouseDown={(e) => {
                e.preventDefault();
                onSizePerPageChange(page);
            }}
            style={{ color: 'pink' }}
        >
            <span className="a hover"
                tabIndex="-1"
                role="menuitem"
                data-page={page}
                onMouseDown={(e) => {
                    e.preventDefault();
                    onSizePerPageChange(page);
                }}
                style={{ color: 'rgba(243, 0, 121, 1)' }}
            >
                {text}
            </span>
        </li>
    );

    const pagination = {
        sizePerPage: 10,
        sizePerPageList: [{ text: "5", value: 5 }, { text: "10", value: 10 }, { text: "50", value: 50 }, { text: "100", value: 100 }], // A numeric array is also available. the purpose of above example is custom the text
        sizePerPageOptionRenderer
    };

    const rowClasses = (row, rowIndex) => {

        if (row.inactiveYN) {
            return "user-inactive";
        }

    };

    return (
        <div>
            <div className="panel-hdr justify-content-end">
                <button type="button" className="pinkTextA btn btn-outline-default margin-0-10" onClick={handleAuditExport}>
                    Export List
                </button>
            </div>

            <div className="row m-4">
                <div className="col-12">
                    <p>Search Criteria</p>
                </div>

                <div className="form-group col-lg-3">
                    <label className="form-label">Event Types</label>
                    <Select
                        mode="multiple"
                        maxTagCount={1}
                        onChange={(selectedEventTypes) => setSelectedEventTypes(selectedEventTypes)}
                        allowClear
                        placeholder="Please select 1 or more"
                        className="form-control-custom w-100"
                        bordered={false}
                        value={selectedEventTypes}
                        showSearch
                        virtual={false}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                    >
                        {searchItems.eventTypes.map((et, idx) => <Option key={idx} value={et.eventTypeID}>{et.eventType}</Option>)}
                    </Select>
                </div>

                <div className="form-group col-lg-3">
                    <label className="form-label">Users</label>
                    <Select
                        mode="multiple"
                        maxTagCount={1}
                        onChange={(selectedUsers) => setSelectedUsers(selectedUsers)}
                        allowClear
                        placeholder="Please select 1 or more"
                        className="form-control-custom w-100"
                        bordered={false}
                        value={selectedUsers}
                        showSearch
                        virtual={false}
                        filterOption={(input, option) => option?.children[0].toLowerCase().includes(input.toLowerCase()) || option?.children[2].toLowerCase().includes(input.toLowerCase())}
                    >
                        {searchItems.users.map((u, idx) => <Option key={idx} value={u.userID}>{u.fName} {u.lName}</Option>)}
                    </Select>
                </div>

                <div className="form-group col-lg-3">
                    <label className="form-label">MRN</label>
                    <input {...register("mrn")} className="form-control-custom" />
                </div>

                <div className="form-group col-lg-3">
                    <label className="form-label">Facility</label>
                    {Globals.associatedFacilities?.length === 1 ?
                        <div>{Globals.associatedFacilities[0].facilityModel.facilityName}</div>
                        :
                        <Select
                            mode="multiple"
                            maxTagCount={1}
                            onChange={(sf) => setSelectedFacilities(sf)}
                            allowClear
                            placeholder="Please select 1 or more"
                            className="form-control-custom w-100"
                            bordered={false}
                            value={selectedFacilities}
                            showSearch
                            virtual={false}
                            filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                        >
                            {Globals.associatedFacilities.map((fac, idx) => <Option key={idx} value={fac.facilityModel.facilityID}>{fac.facilityModel.facilityNumber && fac.facilityModel.facilityNumber + " - "}{fac.facilityModel.facilityName}</Option>)}
                        </Select>
                    }


                </div>

                <div className="form-group col-lg-3">
                    <label className="form-label">Event Date/Time</label>

                    <Controller
                        control={control}
                        name='eventTimeStart'
                        render={({ field }) => (
                            <DatePicker
                                placeholderText='Select date'
                                onChange={(date) => setValue('eventTimeStart', date)}
                                selected={eventTimeStart || null}
                                showTimeSelect
                                selectsStart
                                startDate={eventTimeStart}
                                endDate={eventTimeEnd}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="form-control-custom"
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name='eventTimeEnd'
                        render={({ field }) => (
                            <DatePicker
                                placeholderText='Select date'
                                onChange={(date) => setValue('eventTimeEnd', date)}
                                selected={eventTimeEnd || null}
                                showTimeSelect
                                selectsEnd
                                startDate={eventTimeStart}
                                endDate={eventTimeEnd}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="form-control-custom mt-4"
                            />
                        )}
                    />
                </div>

                <div className="form-group col-lg-3">
                    <label className="form-label">Exam Date/Time</label>

                    <Controller
                        control={control}
                        name='examTimeStart'
                        render={({ field }) => (
                            <DatePicker
                                placeholderText='Select date'
                                onChange={(date) => setValue('examTimeStart', date)}
                                selected={examTimeStart || null}
                                showTimeSelect
                                selectsStart
                                startDate={examTimeStart}
                                endDate={examTimeEnd}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="form-control-custom"
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name='examTimeEnd'
                        render={({ field }) => (
                            <DatePicker
                                placeholderText='Select date'
                                onChange={(date) => setValue('examTimeEnd', date)}
                                selected={examTimeEnd || null}
                                showTimeSelect
                                selectsEnd
                                startDate={examTimeStart}
                                endDate={examTimeEnd}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="form-control-custom mt-4"
                            />
                        )}
                    />
                </div>

                <div className="form-group col-lg-3">
                    <label className="form-label">Result Date/Time</label>

                    <Controller
                        control={control}
                        name='resultTimeStart'
                        render={({ field }) => (
                            <DatePicker
                                placeholderText='Select date'
                                onChange={(date) => setValue('resultTimeStart', date)}
                                selected={resultTimeStart || null}
                                showTimeSelect
                                selectsStart
                                startDate={resultTimeStart}
                                endDate={resultTimeEnd}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="form-control-custom"
                            />
                        )}
                    />

                    <Controller
                        control={control}
                        name='resultTimeEnd'
                        render={({ field }) => (
                            <DatePicker
                                placeholderText='Select date'
                                onChange={(date) => setValue('resultTimeEnd', date)}
                                selected={resultTimeEnd || null}
                                showTimeSelect
                                selectsEnd
                                startDate={resultTimeStart}
                                endDate={resultTimeEnd}
                                dateFormat="MMMM d, yyyy h:mm aa"
                                className="form-control-custom mt-4"
                            />
                        )}
                    />
                </div>

                <div className="form-group col-3 text-center align-self-center">
                    <button type="button" className="btn btn-submit" onClick={handleSearch}>
                        Search
                    </button>
                </div>
            </div>

            
                <Card className="pinkCard">
                    <Card.Header><span>Audit History</span>
                    </Card.Header>
                <Card.Body>
                    {loading ?
                        <div className="loading m-5 text-center d-flex justify-content-center">
                            <span className="frame-heading"><ReactLoading className='tableLoading' type={"spokes"} color={'#F30079'} height={30} width={30} /> Loading ...</span>
                        </div>
                        :
                        <div className="fullTable">
                            {state.loaded ?
                                <ToolkitProvider
                                    keyField='tempID'
                                    data={state.auditInfo}
                                    columns={columns}
                                    columnToggle
                                    search
                                >
                                    {
                                        props => (
                                            <div>
                                                <hr />
                                                <BootstrapTable
                                                    pagination={paginationFactory(pagination)}
                                                    {...props.baseProps}
                                                    rowEvents={rowEvents}
                                                    selectRow={selectRow}
                                                    rowClasses={rowClasses}
                                                    hover condensed />
                                            </div>
                                        )
                                    }
                                </ToolkitProvider>
                                :
                                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No data found. Use the search criteria above." />

                            }
                        </div>
                    }
                    
                    </Card.Body>

                </Card>
        </div>
    );
}

export default withRouter(ViewAuditHistory);