import Axios from '../../config/axios';

export function getManageFacilityInformation(facilityID) {
    return Axios.get('/api/GetManageFacilityInformation', {
        params: {
            facilityID
        }
    }).then(response => response.data);
}

export function updateFacilityInformation(facilityVM) {
    return Axios.post('/api/UpdateFacilityInformation', facilityVM).then(response => response.data);
}

export function insertFacilityLogo(facilityLogo, facilityID) {
    let formdata = new FormData();
    formdata.append('file', facilityLogo);
    formdata.append('facilityID', facilityID);

    return Axios.post('/api/InsertFacilityLogo', formdata).then(response => response.data);
}

export function getProvidersByFacility(facilityID) {
    return Axios.get('/api/GetProvidersByFacility', {
        params: {
            facilityID
        }
    }).then(response => response.data);
}

export function getDropdownValuesByFacility(facilityID) {
    return Axios.get('/api/GetDropdownValuesByFacility', {
        params: {
            facilityID
        }
    }).then(response => response.data);
}

