import { useEffect } from "react";

export default function LockPatientRecord(props) {

    useEffect(() => {
        window.addEventListener("beforeunload", (ev) => {
            ev.preventDefault();
            localStorage.setItem("didUnload", true);
        });
        //return () => {
        //    console.log("Did leave")
        //}
    }, []);

    return <div>
        {props.children}
    </div>
}