import React, { Component } from 'react';
import Card from 'react-bootstrap/Card';
import '../../css/card.css';
import { Fragment } from 'react';

import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';

import NumberFormat from 'react-number-format';
import { dateWithNoTimezone } from '../Helpers/DateFormat';

export class PrimaryResultCard extends Component {

    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.readOnly !== prevState.readOnly) {
            return {readOnly: nextProps.readOnly}
        }

        if (nextProps.isFollowUpShown !== prevState.isFollowUpShown) {
            return { isFollowUpShown: nextProps.isFollowUpShown }
        }

        return null;
    }

    constructor(props) {
        super(props);
        this.state = {
            examHistory: props.examHistory,
            readOnly: props.readOnly,
            recallableDisabled: props.recallableDisabled,
            biradsList: props.biradsList,
            biradsFindings: props.biradsFindings,
            allBiopsyTypes: props.allBiopsyTypes,
            riskGrades: props.riskGrades,
            recommendationsList: props.biradRecommendations[props.historyItem.birad],
            recallTypesList: props.recallTypesList,
            biradRecommendations: props.biradRecommendations,
            newBiradRecommendations: props.newBiradRecommendations,
            findingsList: props.findingsList,
            examInfo: props.examInfo,
            primaryResultDate: props.primaryResultDate,
            formFields: props.formFields,
            densityList: props.densityList,
            radiologistList: props.radiologistList,
            examRadiologistList: props.examRadiologistList,
            categoryName: props.categoryName,
            examIsSCRMAM: props.examIsSCRMAM,
            examIsRAP: props.examIsRAP,
            isFollowUpShown: props.isFollowUpShown
        }

    }

    handleExamHistoryChange = (event, i) => {
        const target = event.target;
        var value = null;
        const name = target.name;

        var result = this.state.examHistory[i];
        let formFields = { ...this.state.formFields };
        let ffDirty = true;

        let formFieldsTouched = false;

        let autoCompleted = false;


        if (target.type === 'checkbox') {
            if (name === 'losttoFollowUp' || name === 'closedforDxExam') {
                value = target.checked ? "1" : "0";
                if (value === '1') {
                    autoCompleted = true;
                    formFields.isExamCompleteYN = 1;
                } else {
                    formFields.isExamCompleteYN = 0;
                }
                formFieldsTouched = true;
            } 
            else {
                value = target.checked ? '1' : '0';
            }

        }
        else {

            this.isDirty = true;

            switch (target.name) {
                case 'birad':
                    let bir = this.state.biradsList.find(birad => birad?.birads === target.value);

                    const recommendationsList = this.state.biradRecommendations[bir.birads];
                    const newRecommendationsAddList = bir ? this.state.newBiradRecommendations[bir.birads] : [];

                    var recommendationID = null;

                    newRecommendationsAddList.forEach(rec => {
                        if (rec.biradRecommendation?.defaultYN) {
                            recommendationID = rec.biradRecommendation.recommendationID;
                        }
                    });

                    let r1 = recommendationsList.find(rec => rec.recommendationID === recommendationID);

                    var recallTypeID = (r1 && !this.state.recallableDisabled) ? r1.defaultRecallTypeID : null;
                    var daysUntilRecall = (r1 && !this.state.recallableDisabled) ? r1.daysUntilRecall : null;

                    if (recallTypeID)
                        result.recallTypeID = recallTypeID;
                    else
                        result.recallTypeID = '00000000-0000-0000-0000-000000000000';

                    if (daysUntilRecall) {
                        var recallDate = Moment(new Date(this.state.examInfo.patientExamModel.examDateTime)).add(daysUntilRecall, 'day').toDate();
                        result.recallDate = recallDate;

                    } else {
                        result.recallDate = null;
                    }

                    if (recommendationID)
                        result.recommendationID = recommendationID;
                    else
                        result.recommendationID = '';
                    var isDefaultComplete = bir ? bir.examAutoCompleteYN : null;

                    
                    var defaultCompleteFromRec = r1 ? r1.examAutoCompleteYN : null;

                    if (isDefaultComplete || defaultCompleteFromRec) {
                        formFields.isExamCompleteYN = 1;
                    } else {
                        formFields.isExamCompleteYN = 0;
                    }

                    formFieldsTouched = true;

                    if (bir?.birads === 'B4' || bir?.birads === 'B5') {
                        result.showBiopsyRefused = true;
                    } else {
                        result.showBiopsyRefused = false;
                    }

                    this.setState({
                        recommendationsList
                    });

                    ffDirty = true;

                    break;
                case 'recommendationID':

                    let recommend = this.state.recommendationsList.find(rec => rec.recommendationID === target.value);

                    var recallTypeID = (recommend && !this.state.recallableDisabled) ? recommend.defaultRecallTypeID : '';
                    var daysUntilRecall = (recommend && !this.state.recallableDisabled) ? recommend.daysUntilRecall : null;

                    var defaultCompleteFromRec = recommend ? recommend.examAutoCompleteYN : null;
                    
                    if (defaultCompleteFromRec) {
                        formFields.isExamCompleteYN = 1;
                    } else {
                        formFields.isExamCompleteYN = 0;
                    }
                    formFieldsTouched = true;

                    if (recallTypeID)
                        result.recallTypeID = recallTypeID;

                    if (daysUntilRecall) {
                        var recallDate = Moment(this.state.examInfo.patientExamModel.examDateTime).add(daysUntilRecall, 'day').toDate();
                        result.recallDate = recallDate;

                    }

                    break;
                case 'densityID':
                    var density = this.state.densityList.find(d => d.breastDensityID === target.value);

                    result.isBreastDense = density ? density.isDenseYN : 0;

                    break;
                default:
                    break;

            }

            value = target.value;

        }

        var missingFields = false;
        var missingRecallDate = false;

        var pastRecallDate = false;


        if (name !== 'closedforDxExam' ||
            name !== 'losttoFollowUp' ||
            name !== 'notes') {
            if (value === null || value === '' || !value) {
                missingFields = true;
            }
        }

        result[name] = value;
        result["isDirty"] = true;

        if (!result.resultDate ||
            !result.radiologistID ||
            !result.birad ||
            !result.recommendationID || result.recommendationID === '' || result.recommendationID === '00000000-0000-0000-0000-000000000000' ||
            (this.state.examIsSCRMAM && (!result.densityID || result.densityID === '' || result.densityID === '00000000-0000-0000-0000-000000000000')) ||
            (!this.state.recallableDisabled && (!result.recallTypeID || result.recallTypeID === '' || result.recallTypeID === '00000000-0000-0000-0000-000000000000') && (result.birad === 'B1' || result.birad === 'B2')) ||
            ((!result.findingID || result.findingID === '' || result.findingID === '00000000-0000-0000-0000-000000000000') && this.state.findingsList?.length > 0)) {

            missingFields = true;
        } else {

            missingFields = false;
        }

        if (!this.state.recallableDisabled && !result.recallDate && (result.birad === 'B1' || result.birad === 'B2' || result.birad === 'NB')) {
            missingRecallDate = true;
        }

        if (result.recallDate && Moment(result.recallDate).isBefore(Moment(), "day")) {
            pastRecallDate = true;
        }

        const examHistory = [...this.state.examHistory];
        examHistory[i] = result;

        this.setState({
            examHistory,
            formFields,
            ffDirty,
            missingFields,
            missingRecallDate,
            pastRecallDate
        });

        this.props.updateFollowUpInfo(examHistory, formFields, formFieldsTouched, ffDirty, missingFields, missingRecallDate, pastRecallDate, autoCompleted);
    }

    handleExamHistoryDateChange = (date, dateName, i) => {

        var result = this.state.examHistory[i];

        var pastRecallDate = false;


        const examHistory = [...this.state.examHistory];

        if (dateName === 'recallDate') {
            result.recallDate = date;
        }

        if (!this.state.recallableDisabled && ((result?.birads === 'B1' || result?.birads === 'B2') && dateName === 'recallDate' && !date)) {
            this.setState({
                missingRecallDate: true
            });
        }

        if (result.recallDate && dateWithNoTimezone(result.recallDate) < new Date() && i === examHistory.length - 1) {
            pastRecallDate = true;
        }

        result[dateName] = date;
        result["isDirty"] = true;

        examHistory[i] = result;

        this.isDirty = true;

        this.setState({
            examHistory,
            ffDirty: true
        });

        this.props.updateHistoryDateInfo(examHistory, true, null, pastRecallDate);
    }

    render() {
        const history = this.props.historyItem;

        const i = this.props.idx;

        const showRiskGrades = (findingID, birad) => {
            const finding = this.state.biradsFindings[birad] && this.state.biradsFindings[birad].find(f => f.findingID === findingID);

            return finding?.riskGradeYN;
        }

        const getRiskGrades = (findingID) => {
            return this.state.riskGrades[findingID];
        }

        const showBiopsyTypes = (birad) => {
            return birad === 'NB' || birad === 'PB' ? true : false;
        }

        const pbSelected = (birad) => {
            return birad === 'PB' ? true : false;
        }

        const selectedDateVal = (history) => {
            if (history && history.resultDate) {
                if (!(history.resultDate instanceof Date) && history.resultDate.includes("00:00:00Z")) {
                    return dateWithNoTimezone(history.resultDate);
                } else {
                    return Moment(history.resultDate).toDate();
                }
            }
            return null;
        }

        return (
            <Card className="pinkCard">
                <Card.Header><span>Primary Result</span>
                    {this.props.examHistory.length === 1 && <i id="deleteIcon" className="far fa-trash-alt fa-125x color-pink float-right hover" onClick={(e) => this.props.deleteResult(e, 0)}></i>}
                </Card.Header>
                <Card.Body>

                    <div className="row">
                        <div className="form-group col-lg-2">
                            <label className="form-label">Result Date <span className="color-pink">*</span></label>
                            <DatePicker
                                selected={selectedDateVal(history)}
                                onChange={(date, dateName, index) => this.handleExamHistoryDateChange(date, "resultDate", 0)}
                                className="form-control-custom"
                                readOnly={this.state.readOnly}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                    <NumberFormat format="##/##/####" mask="_" />
                                }
                            />
                        </div>

                        {!this.state.examIsRAP &&
                            <div className="form-group col-lg-2">
                                <label className="form-label">Radiologist <span className="color-pink">*</span></label>
                                <select className="form-control-custom" value={history.radiologistID || ''} name="radiologistID" onChange={(e) => this.handleExamHistoryChange(e, 0)} disabled={this.state.readOnly}>
                                    <option />
                                    {this.state.radiologistList.map((rad, idx) => <option key={idx} value={rad.radiologistID}>{rad.fName + ' ' + rad.lName}</option>)}

                                </select>
                            </div>
                        }

                        <div className="form-group col-lg-2">
                            <label className="form-label">BIRADS/Results <span className="color-pink">*</span></label>
                            <select className="form-control-custom" name="birad" value={history.birad || ''} onChange={(e) => this.handleExamHistoryChange(e, 0)} disabled={this.state.readOnly}>
                                <option />
                                {this.state.biradsList.filter(b => b.primaryResultYN).map((birad, idx) => <option key={idx} value={birad.birads}>{birad.description}</option>)}

                            </select>
                        </div>

                        <div className="form-group col-lg-2">
                            {this.state.biradsFindings[history.birad] &&
                                <Fragment>
                                    <label className="form-label">Finding <span className="color-pink">*</span></label>
                                <select className="form-control-custom" name="findingID" value={history.findingID || ''} onChange={(e) => this.handleExamHistoryChange(e, 0)} disabled={this.state.readOnly}>
                                        <option />
                                        {this.state.biradsFindings[history.birad].map((f, idx) => <option key={idx} value={f.findingID}>{f.findingDescription}</option>)}
                                    </select>
                                </Fragment>
                            }
                        </div>

                        <div className="form-group col-lg-2">
                            {showRiskGrades(history.findingID, history.birad) &&

                                <Fragment>
                                    {getRiskGrades(history.findingID)?.length === 1 ?
                                        <Fragment>
                                            <label className="form-label">Risk Grade</label>
                                        <input className="form-control-custom no-border" defaultValue={getRiskGrades(history.findingID)[0].riskGradeDescription} readOnly={this.state.readOnly}/>
                                        </Fragment>
                                        :
                                        <Fragment>
                                            <label className="form-label">Risk Grade</label>
                                        <select className="form-control-custom" value={history.riskGradeID || ''} name="riskGradeID" onChange={(e) => this.handleExamHistoryChange(e, 0)} disabled={this.state.readOnly}>
                                                <option />
                                                {getRiskGrades(history.findingID).map((rg, idx) => <option key={idx} value={rg.riskGradeID}>{rg.riskGradeDescription}</option>)}
                                            </select>
                                        </Fragment>
                                    }
                                </Fragment>
                            }
                        </div>

                        <div className="col-lg-2">
                            {showBiopsyTypes(history.birad) &&
                                <Fragment>
                                    <label className="form-label">Biopsy Type</label>
                                <select className="form-control-custom" value={history.biopsyTypeID || ''} name="biopsyTypeID" onChange={(e) => this.handleExamHistoryChange(e, 0)} disabled={this.state.readOnly}>
                                        <option />
                                        {this.state.allBiopsyTypes.map((b, idx) => <option key={idx} value={b.biopsyTypeID}>{b.biopsyTypeDescription}</option>)}
                                    </select>
                                </Fragment>
                            }
                        </div>

                        

                        <div className="col-lg-2">
                            {(history.birad === 'B4' || history.birad === 'B5') &&
                                <Fragment>
                                    <label className="form-label">Biopsy refused/not performed</label>
                                    <div className="form-control-custom no-border">
                                        <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" id="bxNotPerformed" name="bxNotPerformed" value={history.bxNotPerformed || "0"} onChange={(e) => this.handleExamHistoryChange(e, 0)} checked={history.bxNotPerformed === "1" ? true : false} disabled={this.state.readOnly} />
                                            <label className="custom-control-label"></label>
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            {(history.birad === 'PB' || history.birad === 'NB') &&
                                <Fragment>
                                    <label className="form-label">Screening Rad</label>
                                    <select className="form-control-custom" value={history.screeningRadiologistID || ''} name="screeningRadiologistID" onChange={(e) => this.handleExamHistoryChange(e, 0)}>
                                        <option />
                                        {this.state.examRadiologistList.map((rad, idx) => <option key={idx} value={rad.radiologistID}>{rad.fName + ' ' + rad.lName}</option>)}
                                    </select>
                                </Fragment>
                            }
                        </div>

                        <div className="form-group col-lg-4">
                            <label className="form-label">Recommendation <span className="color-pink">*</span></label>
                            <select className="form-control-custom" value={history.recommendationID || ''} name="recommendationID" onChange={(e) => this.handleExamHistoryChange(e, 0)} disabled={this.state.readOnly}>
                                <option />
                                {this.state.recommendationsList?.map((rec, idx) => <option key={idx} value={rec.recommendationID}>{rec.description}</option>)}

                            </select>
                        </div>

                        <div className="form-group col-lg-6">
                            <label className="form-label">Density {this.state.examIsSCRMAM && <span className="color-pink">*</span>}</label>
                            <select className="form-control-custom" value={history.densityID || ''} name="densityID" onChange={(e) => this.handleExamHistoryChange(e, 0)} disabled={this.state.readOnly}>
                                <option />
                                {this.state.densityList.map((density, idx) => <option key={idx} value={density.breastDensityID}>{density.densityDescription}</option>)}

                            </select>
                        </div>

                        {/*<div className="form-group col-lg-3">*/}
                        {/*    <label className="form-label">Recall Type {(!this.state.recallableDisabled && (history.birad === 'B1' || history.birad === 'B2') ) && <span className="color-pink">*</span>}</label>*/}
                        {/*    <select className="form-control-custom" value={history.recallTypeID || ''} name="recallTypeID" onChange={(e) => this.handleExamHistoryChange(e, 0)} readOnly={this.state.readOnly} disabled={this.state.recallableDisabled || this.state.readOnly}>*/}
                        {/*        <option />*/}
                        {/*        {this.state.recallTypesList.map((type, idx) => <option key={idx} value={type.recallTypeID}>{type.recallTypeDescription}</option>)}*/}

                        {/*    </select>*/}
                        {/*</div>*/}

                        <div className="form-group col-lg-3">
                            <label className="form-label">Recall Date</label>
                            <DatePicker
                                selected={(history && history.recallDate) && Moment(history.recallDate).toDate()}
                                onChange={(date, dateName, index) => this.handleExamHistoryDateChange(date, "recallDate", 0)}
                                className="form-control-custom"
                                readOnly={this.state.readOnly}
                                disabled={this.state.recallableDisabled}
                                showMonthDropdown
                                showYearDropdown
                                dropdownMode="select"
                                customInput={
                                    <NumberFormat format="##/##/####" mask="_" />
                                }
                            />
                        </div>

                        {!this.state.isFollowUpShown ?
                            <div className="col-lg-4">
                                <div className="row">
                                    {(history.birad === 'B0' || history.birad === 'B3' || history.birad === 'B4' || history.birad === 'B5') &&
                                        <div className="col-lg-6">
                                            <label className="form-label">Non-Compliant</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input" id="losttoFollowUp" name="losttoFollowUp" value={history.losttoFollowUp || "0"} onChange={(e) => this.handleExamHistoryChange(e, 0)} checked={history.losttoFollowUp === "1" ? true : false} disabled={this.state.readOnly} />
                                                    <label className="custom-control-label"></label>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {!(this.state.categoryName === 'SCRMAM') &&
                                        <div className="col-lg-6">
                                            <label className="form-label">Close for Dx</label>
                                            <div className="form-control-custom no-border">
                                                <div className="custom-control custom-checkbox custom-control-inline">
                                                    <input type="checkbox" className="custom-control-input" id="closedforDxExam" name="closedforDxExam" value={history.closedforDxExam || "0"} onChange={(e) => this.handleExamHistoryChange(e, 0)} checked={history.closedforDxExam === '1' ? true : false} disabled={this.state.readOnly} />
                                                    <label className="custom-control-label"></label>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                </div>
                            </div>
                            :
                            <div className="col-lg-4"></div>
                        }

                        <div className="form-group col-lg-8">
                            <label className="form-label">Notes</label>
                            <textarea className="form-control-custom" rows="6" value={history.notes || ''} name="notes" onChange={(e) => this.handleExamHistoryChange(e, 0)} disabled={this.state.readOnly} />
                        </div>

                        <div className="form-group col-lg-12">
                            <label className="form-label">Last Modified By</label>
                            <input className="form-control-custom" readOnly value={history.lastModifiedByName} />
                        </div>

                    </div>

                </Card.Body>
            </Card>
        );
    }
}