import Axios from '../../config/axios';

export function getLoadInfo(userID) {
    return Axios.get('/api/GetLoadInfo', {
        params: {
            userID
        }
    }).then(response => response.data).catch(error => error);
}

export function getInactiveUsers() {
    return Axios.get('/api/GetInactiveUsers').then(response => response.data).catch(error => error);
}

export function getAllUsers() {
    return Axios.get('/api/GetAllUsers').then(response => response.data).catch(error => error);
}

export function activateUser(postdata) {
    return Axios.post('/api/ActivateUser', postdata).then(response => response.data).catch(error => error);
}

export function updateUserWorklistSortPreference(postdata) {
    return Axios.post('/api/UpdateUserWorklistSortPreference', postdata).then(response => response.data).catch(error => error);
}