import React, { Component, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import { getRecallInformation, recallPrintTypes, getPrintRecallSearchResults, groupedRecallPrintTypes, printTypes } from '../../common/services/RecallService';
import { getPatientInformationById, userAssociatedFacilities, getPatientInformationByIDNew } from '../../common/services/ApiService';
import { takeWhile } from 'rxjs/operators';

import { combineLatest } from 'rxjs';

import { Empty, Select, Spin, message, TreeSelect, Table, Input, Divider, Tabs } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import DatePicker from 'react-datepicker';

import "react-datepicker/dist/react-datepicker.css";

import NumberFormat from 'react-number-format';

import Axios from '../../config/axios';
import Globals from '../../config/globals';
import Moment from 'moment';
import { filterTableFromColumns } from '../Helpers/FilterTable';

const { Search } = Input;

class RecallHome extends Component {

    constructor(props) {
        super(props);

        this.state = {
            facilities: [],
            recallPrintTypes: [],
            selectedFacility: '',
            loaded: false,
            selectedRecallPrintTypes: [],
            selectedRPT: [],
            searchResults: [],
            filteredSearchResults: [],
            loadingResults: false,
            landed: true,
            selected: [],
            formFields: {
                showPreviousPrinted: false,
                showUpcoming: false
            },
            selectedDates: {
                startDate: null,
                endDate: null
            },
            selectedPatient: {},
            groupedItems: [],
            nonModifiedGroupItems: {}
        }

        this.alive = false;
    }

    componentDidMount() {


        this.alive = true;

        userAssociatedFacilities.pipe(takeWhile(() => this.alive)).subscribe(response => {
            let facilities = response;
            if (facilities?.length > 0) {

                let selectedFacilityObj = facilities.find(f => f.defaultFacility === 1)?.facilityModel || facilities[0].facilityModel;
                let b3AutoRecall = selectedFacilityObj.b3AutoRecallYN;

                let selectedFacility = facilities.find(f => f.defaultFacility === 1)?.facilityModel?.facilityID || facilities[0].facilityModel.facilityID;

                getRecallInformation(selectedFacility);

                this.setState({
                    facilities: facilities.filter(item => item.facilityModel.inactiveYN !== 1),
                    //selectedFacility,
                    b3AutoRecall
                });
            }
        });

        combineLatest(
            recallPrintTypes,
            groupedRecallPrintTypes,
            printTypes
        ).pipe(takeWhile(() => this.alive)).subscribe(response => {
            if (response[0].length > 0) {
                this.setState({
                    recallPrintTypes: response[0],
                    loaded: true
                });
            }

            if (response[1]) {

                this.setState({
                    nonModifiedGroupItems: response[1],
                    printTypes: response[2]
                }, () => this.setGroupedItems());

                //let groupedItems = [];
                //let obj = response[1];

                //let idx = 0;

                //for (const key of Object.keys(obj)) {
                //    let printType = response[2].find(pt => pt.printTypeID == key)?.printType;

                //    let children = obj[key].map(rpt => ({ title: rpt.recallPrintType, key: `${rpt.recallPrintTypesID}`, value: `${rpt.recallPrintTypesID}` }));

                //    let itemToAdd = {
                //        title: printType,
                //        value: `all:${key}`,
                //        key: `all:${key}`,
                //        children
                //    }

                //    if (!groupedItems.find(i => i.value === `all:${itemToAdd.value}`)) {
                //        groupedItems.push(itemToAdd);
                //    }
                //    idx++;
                //}

                //this.setState({
                //    groupedItems,
                //    nonModifiedGroupItems: response[1]
                //});
            }
        });
    }

    componentWillUnmount() {
        this.alive = false;
    }

    handleFacilityChange = (selectedFacility) => {

        getRecallInformation(selectedFacility);

        let selectedFacilityObj = this.state.facilities.find(f => f.facilityModel.facilityID === selectedFacility);

        let b3AutoRecall = selectedFacilityObj.facilityModel.b3AutoRecallYN;

        this.setState({
            selectedFacility,
            selected: [],
            b3AutoRecall,
            selectedRPT: []
        }, () => this.setGroupedItems());
    }

    setGroupedItems = () => {
        let groupedItems = [];
        let obj = { ...this.state.nonModifiedGroupItems };

        let idx = 0;

        for (const key of Object.keys(obj)) {
            let printType = this.state.printTypes.find(pt => pt.printTypeID == key)?.printType;

            let filteredList = obj[key];
            if (this.state.b3AutoRecall === 0) {
                filteredList = obj[key].filter(rpt => (!rpt.recallPrintType.includes('Diagnostic Ultrasound') && !rpt.recallPrintType.includes('Diagnostic Mammogram')));
            }

            let children = filteredList.map(rpt => ({ title: rpt.recallPrintType, key: `${rpt.recallPrintTypesID}`, value: `${rpt.recallPrintTypesID}` }));

            let itemToAdd = {
                title: printType,
                value: `all:${key}`,
                key: `all:${key}`,
                children
            }

            if (!groupedItems.find(i => i.value === `all:${itemToAdd.value}`)) {
                groupedItems.push(itemToAdd);
            }
            idx++;
        }

        this.setState({
            groupedItems
        });
    }

    handleRecallPrintTypesChange = (selectedRecallPrintTypes) => {
        this.setState({
            selectedRecallPrintTypes,
            selected: []
        });
    }

    handleNewDateChange = (name, date) => {
        this.setState({ selectedDates: { ...this.state.selectedDates, [name]: date } });
    }

    handleTreeChange = (selectedRPT) => {
        let selectedRecallPrintTypes = [];

        selectedRPT.forEach(id => {
            if (id.includes('all:')) {
                let key = id.substring(4);

                let allItems = this.state.nonModifiedGroupItems[key];

                if (this.state.b3AutoRecall === 0) {
                    allItems = allItems.filter(rpt => (!rpt.recallPrintType.includes('Diagnostic Ultrasound') && !rpt.recallPrintType.includes('Diagnostic Mammogram')));
                }

                allItems.forEach(item => {
                    selectedRecallPrintTypes.push(item.recallPrintTypesID);
                });
            } else {
                selectedRecallPrintTypes.push(id);
            }
        });

        this.setState({ selectedRecallPrintTypes, selectedRPT });
    };

    handleSubmit = () => {
        if (this.state.selectedRecallPrintTypes.length === 0) {
            message.error('Please fill select a facility and at least one recall print type');
        } else {
            this.setState({
                loadingResults: true,
                landed: false
            });
            var rpt = [];

            this.state.selectedRecallPrintTypes.forEach(r => {
                rpt.push(this.state.recallPrintTypes.find(t => t.recallPrintType.recallPrintTypesID === r));
            });

            getPrintRecallSearchResults(this.state.selectedFacility, rpt, this.state.formFields.showPreviousPrinted, this.state.formFields.showUpcoming, this.state.formFields.showInactivePatients).then(data => {

                this.setState({
                    searchResults: data ? data : [],
                    filteredSearchResults: data ? data : [],
                    loadingResults: false,
                    selected: []
                });
            });
        }
    }

    handleBtnClick = () => {
        if (!this.state.selected.includes(2)) {
            this.setState(() => ({
                selected: [...this.state.selected, 2]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== 2)
            }));
        }
    }

    handleOnSelect = (row, isSelect) => {
        if (isSelect) {
            this.setState(() => ({
                selected: [...this.state.selected, row.patientID]
            }));
        } else {
            this.setState(() => ({
                selected: this.state.selected.filter(x => x !== row.patientID)
            }));
        }
    }

    handleOnSelectAll = (isSelect, rows) => {
        const ids = this.state.filteredSearchResults.map(r => r.patientID);
        if (isSelect) {
            this.setState(() => ({
                selected: ids
            }));
        } else {
            this.setState(() => ({
                selected: []
            }));
        }
    }

    handleBulkPrint = () => {
        message.success(this.state.selected.length + ' report(s) have been printed');

        let patientRecalls = [];

        this.state.selected.forEach(r => {
            let item = this.state.searchResults.find(sr => sr.patientID === r);

            item.recallDate = item.recallDate ? new Date(item.recallDate) : null;
            item.dob = item.dob ? new Date(item.dob) : null;
            item.nextApptDate = item.nextApptDate ? new Date(item.nextApptDate) : null;
            patientRecalls.push(item);
        });

        const q = Axios.defaults.baseURL + "api/RenderReportFromRecallSelection";

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientRecallsString";
        input.value = JSON.stringify(patientRecalls);
        form.appendChild(input);

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "userid";
        input.value = Globals.userInfo.userId;
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ formFields: { ...this.state.formFields, [name]: value } });
    }

    selectPatient(patient, categoryList) {
        if (patient) {
            getPatientInformationByIDNew(patient.patientModel.patientID).then(data => {

                this.props.history.push({
                    pathname: '/patient-dashboard',
                    state: { selectedObject: data.patientInformation, examInfo: data.examInfo, filteredCategoryList: categoryList, patientEthnicity: data.patientEthnicity }
                });
            });

            //getAllPatientExamInfoByPatient(patient.patientModel.patientID).then(data => {
            //    this.props.history.push({
            //        pathname: '/patient-dashboard',
            //        state: { selectedObject: patient, examInfo: data }
            //    });
            //});
        }
    }

    handleRenderSearchResults = () => {
        const q = Axios.defaults.baseURL + "api/RenderRecallSearchResults";

        var form = document.createElement("form");
        form.target = "_blank";
        form.method = "POST";
        form.action = q;
        form.style.display = "none";

        var input = document.createElement("input");
        input.type = "hidden";
        input.name = "patientRecallsString";
        input.value = JSON.stringify(this.state.filteredSearchResults);
        form.appendChild(input);

        document.body.appendChild(form);
        form.submit();
        document.body.removeChild(form);
    }

    filterByDateRange = () => {
        const start = this.state.selectedDates.startDate;
        const end = this.state.selectedDates.endDate;
        if (start) start.setHours(0, 0, 0, 0);
        if (end) end.setHours(23, 59, 59, 999);

        let filteredResults = this.state.filteredSearchResults.filter(item => {

            let date = new Date(item.recallDate);

            var date_utc = new Date(date.getUTCFullYear(), date.getUTCMonth(),
                date.getUTCDate(), 0, 0, 0);

            if (start && end) {
                return date_utc >= start.getTime() && date_utc <= end.getTime();
            } else if (start) {
                return date_utc >= start.getTime();
            } else if (end) {
                return date_utc <= end.getTime();
            }
            
        });

        this.setState({ filteredSearchResults: filteredResults });

    }

    handleTabChange = (activeKey) => {
        this.setState({
            filteredSearchResults: this.state.searchResults,
            searchValue: '',
            selectedDates: {
                startDate: null,
                endDate: null
            }
        });
    }

    render() {

        const { SHOW_PARENT } = TreeSelect;

        //const { SearchBar } = Search;
        const { Option } = Select;

        let th = this;

        function folderFormatter(cell, row) {
            return (
                <i id="openChart" className="far fa-folder-open fa-125x color-pink text-center"></i>
            );
        }

        const antColumns = [{
            dataIndex: 'mrn',
            title: 'MRN #',
            sorter: (a, b) => a.mrn?.localeCompare(b.mrn)
        }, {
            dataIndex: 'lName',
            title: 'Last Name',
            sorter: (a, b) => a.lName?.localeCompare(b.lName)
        }, {
            dataIndex: 'fName',
            title: 'First Name',
            sorter: (a, b) => a.fName?.localeCompare(b.fName)
        }, {
            dataIndex: 'dob',
            title: 'DOB',
            render: cell => cell && Moment(new Date(cell)).utc().format('L'),
            sorter: (a, b) => a.dob?.localeCompare(b.dob)
        }, {
            dataIndex: 'recallSentDate',
            title: 'Print Date',
            render: cell => cell && Moment(new Date(cell)).utc().format('L'),
            sorter: (a, b) => a.recallSentDate?.localeCompare(b.recallSentDate)
        }, {
            dataIndex: 'recallType',
            title: 'Recall Type',
            sorter: (a, b) => a.recallType?.localeCompare(b.recallType)
        }, {
            dataIndex: 'recallDate',
            title: 'Recall Date',
            render: cell => cell && Moment(new Date(cell)).utc().format('L'),
            sorter: (a, b) => a.recallDate?.localeCompare(b.recallDate)
        }, {
            dataIndex: 'nextApptDate',
            title: 'Next Appt Date',
            render: cell => cell && Moment(new Date(cell)).utc().format('L'),
            sorter: (a, b) => a.nextApptDate?.localeCompare(b.nextApptDate)
        }, {
            dataIndex: 'dum1',
            title: 'Open Chart',
            render: folderFormatter
            }];

        const onSearch = (e) => {
            let value = e.target.value;
            var filteredValue = filterTableFromColumns(th.state.searchResults, value, antColumns);
            th.setState({
                filteredSearchResults: filteredValue,
                searchValue: e.target.value
            });
        };

        const rowSelection = {
            selectedRowKeys: this.state.selected,
            onSelect: this.handleOnSelect,
            onSelectAll: this.handleOnSelectAll
        };

        const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

        const tProps = {
            treeData: this.state.groupedItems,
            value: this.state.selectedRPT,
            onChange: this.handleTreeChange,
            treeCheckable: true,
            showCheckedStrategy: SHOW_PARENT,
            placeholder: 'Please select',
            style: {
                width: '100%',
            },
        };

        return (
            <Fragment>
                {this.state.loaded &&
                    <Fragment>
                        <div className="row">
                            <div className="col-lg-12 mb-4" >
                                <div className="panel-hdr">
                                    <h2>
                                        Recall
                                    </h2>
                                </div>

                            </div>
                        </div>

                        <div className="row ml-4 mb-4 mr-4">
                            <div className="col col-lg-6 col-12">
                                <label className="form-label">Facility</label>
                                <Select
                                    allowClear
                                    placeholder="Please select"
                                    onChange={this.handleFacilityChange}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    value={this.state.selectedFacility}
                                    showSearch
                                    virtual={false}
                                    filterOption={(input, option) => (option.children[0] + option.children[1]).toLowerCase().includes(input.toLowerCase())}
                                >
                                    {this.state.facilities.map((f, idx) => <Option key={idx} value={f.facilityModel.facilityID}>{f.facilityModel.facilityNumber && f.facilityModel.facilityNumber + " - "}{f.facilityModel.facilityName}</Option>)}
                                </Select>

                            </div>
                        </div>
                        <div className="row ml-4 mb-4 mr-4">
                            {/*    <div className="col col-lg-6 col-12">*/}
                            {/*        <label className="form-label">Recall Print Type</label>*/}
                            {/*        <Select*/}
                            {/*            mode="multiple"*/}
                            {/*            allowClear*/}
                            {/*            placeholder="Please select"*/}
                            {/*            onChange={this.handleRecallPrintTypesChange}*/}
                            {/*            className="form-control-custom w-100"*/}
                            {/*            bordered={false}*/}
                            {/*            maxTagCount={1}*/}
                            {/*            value={this.state.selectedRecallPrintTypes}*/}
                            {/*        >*/}
                            {/*        {this.state.recallPrintTypes.map((rpt, idx) => <Option key={rpt.recallPrintType.recallPrintType} value={rpt.recallPrintType.recallPrintType}>{rpt.recallPrintType.recallPrintType}</Option>)}*/}
                            {/*        </Select>*/}
                            {/*</div>*/}

                            <div className="col col-lg-6 col-12">
                                <label className="form-label">Recall Print Type</label>
                                <TreeSelect {...tProps}
                                    className="form-control-custom w-100"
                                    bordered={false}
                                    maxTagCount={1} />
                            </div>

                            <div className="col-lg-2 col-12">
                                <label className="form-label">Show Inactive Patients</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" id="showInactivePatients" name="showInactivePatients" value={this.state.formFields.showInactivePatients || ''} onChange={this.handleInputChange} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>

                            <div className="col-lg-2 col-12">
                                <label className="form-label">Show previously printed letters</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" id="" name="showPreviousPrinted" checked={this.state.formFields.showPreviousPrinted || false} onChange={this.handleInputChange} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-2 col-12">
                                <label className="form-label">Show Patients With Upcoming Appointments</label>
                                <div className="form-control-custom no-border">
                                    <div className="custom-control custom-checkbox custom-control-inline">
                                        <input type="checkbox" className="custom-control-input" id="" name="showUpcoming" checked={this.state.formFields.showUpcoming || false} onChange={this.handleInputChange} />
                                        <label className="custom-control-label"></label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row ml-4 mr-4">
                            <div className="col-lg-6 col-12">
                                <button className="btn btn-outline-default color-pink" onClick={this.handleSubmit}>Search</button>
                            </div>

                            <div className="col-lg-6 col-12 text-right">
                                {this.state.filteredSearchResults.length > 0 && <button className="btn btn-submit mr-3" onClick={this.handleRenderSearchResults}>Print Recall Search Results</button>}
                                {this.state.selected?.length > 0 && <button className="btn btn-submit" onClick={this.handleBulkPrint}>Print {this.state.selected.length} Letter(s)</button>}
                            </div>

                        </div>
                        {this.state.landed ?
                            <div><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<p>Begin by searching on facility and print type</p>} /></div>
                            :
                            <div className="frame-wrap">
                                {this.state.loadingResults ?
                                    <div className="frame-heading"><Spin indicator={antIcon} /> Loading...</div>
                                    :
                                    <Fragment>
                                        <Divider>Filter By </Divider>
                                        <Tabs type="card" className="m-4" onChange={this.handleTabChange} style={{overflow: 'unset !important'}}>
                                            <Tabs.TabPane tab="Recall Date" key="date-range-tab">
                                                <div className="row mt-3">
                                                    <div className="form-group col">
                                                        <div className="form-label">Start Range</div>
                                                        <DatePicker
                                                            onChange={(date) => this.handleNewDateChange('startDate', date)}
                                                            selectsStart
                                                            startDate={this.state.selectedDates.startDate}
                                                            endDate={this.state.selectedDates.endDate}
                                                            className="form-control-custom"
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            placeholderText='Start Date'
                                                            selected={this.state.selectedDates.startDate && Moment(this.state.selectedDates.startDate).toDate()}
                                                            customInput={
                                                                <NumberFormat format="##/##/####" mask="_" />
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group col">
                                                        <div className="form-label">End Range</div>
                                                        <DatePicker
                                                            onChange={(date) => this.handleNewDateChange('endDate', date)}
                                                            selectsEnd
                                                            showMonthDropdown
                                                            showYearDropdown
                                                            dropdownMode="select"
                                                            startDate={this.state.selectedDates.startDate}
                                                            endDate={this.state.selectedDates.endDate}
                                                            className="form-control-custom"
                                                            placeholderText='End Date'
                                                            selected={this.state.selectedDates.endDate && Moment(this.state.selectedDates.endDate).toDate()}
                                                            customInput={
                                                                <NumberFormat format="##/##/####" mask="_" />
                                                            }
                                                        />
                                                    </div>
                                                    <div className="form-group col mt-3">
                                                        <label />
                                                        <button className="btn btn-outline-default color-pink" onClick={this.filterByDateRange}>Filter By Date Range</button>
                                                    </div>
                                                </div>
                                            </Tabs.TabPane>
                                            <Tabs.TabPane tab="Search" key="search-tab">
                                                <Search
                                                    className="mt-3"
                                                    placeholder="Filter by search"
                                                    onChange={onSearch}
                                                    value={this.state.searchValue}
                                                />
                                            </Tabs.TabPane>
                                        </Tabs>
                                        
                                        <hr />
                                        {this.state.filteredSearchResults?.length > 0 ?
                                            <Fragment>
                                                {/*<PaginationProvider*/}
                                                {/*    pagination={paginationFactory(pagination)}*/}
                                                {/*>*/}
                                                {/*    {*/}
                                                {/*        ({*/}
                                                {/*            paginationProps,*/}
                                                {/*            paginationTableProps*/}
                                                {/*        }) => (*/}
                                                {/*            <div>*/}
                                                {/*                <ToolkitProvider*/}
                                                {/*                    keyField='patientID'*/}
                                                {/*                    data={this.state.searchResults}*/}
                                                {/*                    columns={columns}*/}
                                                {/*                    columnToggle*/}
                                                {/*                    search={{ customMatchFunc, searchFormatted: true }}*/}
                                                {/*                >*/}
                                                {/*                    {*/}
                                                {/*                        props => (*/}
                                                {/*                            <div>*/}
                                                {/*                                <hr />*/}
                                                {/*                                */}{/*<SearchBar {...props.searchProps} />*/}
                                                {/*                                <hr />*/}
                                                {/*                                <BootstrapTable*/}
                                                {/*                                    pagination={paginationFactory(pagination)}*/}
                                                {/*                                    {...paginationTableProps}*/}
                                                {/*                                    {...props.baseProps}*/}
                                                {/*                                    rowEvents={rowEvents}*/}
                                                {/*                                    selectRow={selectRow}*/}
                                                {/*                                    hover condensed*/}
                                                {/*                                    filter={filterFactory()} />*/}
                                                {/*                            </div>*/}
                                                {/*                        )*/}
                                                {/*                    }*/}
                                                {/*                </ToolkitProvider>*/}
                                                {/*                <div className="custom-pagination">*/}
                                                {/*                    <SizePerPageDropdownStandalone*/}
                                                {/*                        className="float-left mr-3"*/}
                                                {/*                        {...paginationProps}*/}
                                                {/*                    />*/}
                                                {/*                    <PaginationListStandalone*/}
                                                {/*                        {...paginationProps}*/}
                                                {/*                    />*/}
                                                {/*                    <PaginationTotalStandalone*/}
                                                {/*                        {...paginationProps}*/}
                                                {/*                    />*/}
                                                {/*                </div>*/}
                                                {/*            </div>*/}
                                                {/*        )*/}
                                                {/*    }*/}

                                                {/*</PaginationProvider>*/}
                                                <Table
                                                    rowKey={(record) => record.patientID}
                                                    columns={antColumns}
                                                    dataSource={this.state.filteredSearchResults}
                                                    rowSelection={rowSelection}
                                                    onRow={(row, rowIndex) => {
                                                        return {
                                                            onClick: e => {
                                                                if (e.target.tagName === 'I' && e.target.id === 'openChart'/*e.target.tagName === 'TD' || e.target.tagName === 'TR'*/) {

                                                                    getPatientInformationById(row.patientID).then(data => {
                                                                        this.setState({
                                                                            selectedPatient: data
                                                                        }, () => this.selectPatient(this.state.selectedPatient, row.categoryList));
                                                                    });
                                                                }
                                                            },
                                                            onDoubleClick: e => {
                                                                getPatientInformationById(row.patientID).then(data => {
                                                                    this.setState({
                                                                        selectedPatient: data
                                                                    }, () => this.selectPatient(this.state.selectedPatient, row.categoryList));
                                                                });
                                                            }
                                                        };
                                                    }}
                                                />
                                            </Fragment>
                                            :
                                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<p>No Results</p>} />
                                        }
                                    </Fragment>
                                }

                            </div>
                        }
                    </Fragment>
                }
            </Fragment>
        );
    }
}

export default withRouter(RecallHome);