import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import SlidingPane from 'react-sliding-pane';
import 'react-sliding-pane/dist/react-sliding-pane.css';

import { Table, Modal, Select } from 'antd';

import { useEffect } from 'react';
import { activateUser, getAllUsers } from '../../common/services/UsersService';

import NumberFormat from 'react-number-format';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Moment from 'moment';
import { toast } from '@rickylandino/react-messages';

function ViewInactiveUsers(props) {
    const [inactiveUsers, setInactiveUsers] = useState({
        data: [],
        loaded: false
    });
    const [activeUsers, setActiveUsers] = useState([]);

    const [showPane, setShowPane] = useState(props.showPane);

    const [user, setUser] = useState(null);

    const [showModal, setShowModal] = useState(false);

    const [formFields, setFormFields] = useState({});

    const [errorMsg, setErrorMsg] = useState(null);

    useEffect(() => {
        getAllUsers().then(data => {
            let inactive = data.filter(user => user.inactiveYN === 1);
            let active = data.filter(user => user.inactiveYN === 0);

            setInactiveUsers({
                data: inactive,
                loaded: true
            });

            setActiveUsers(active);
        });
    }, []);

    function hidePane() {
        setShowPane(false);
        props.hidePane();
    }

    function openActivateUser(thisUser) {
        setUser(thisUser);
        setShowModal(true);
    }

    function handleSubmit() {

        let postdata = { ...user };
        postdata.dateTrained = formFields.date;
        postdata.trainedByUserID = formFields.userID;

        if (!postdata.dateTrained || !postdata.trainedByUserID) {
            setErrorMsg("Please fill out all fields");
            return;
        }

        //check if date is in the future
        if (postdata.dateTrained > new Date()) {
            setErrorMsg("Date Trained cannot be in the future");
            return;
        }

        activateUser(postdata).then(data => {
            if (data) {
                toast.success("User has been activated");
                closeModal();

                //remove user from inactive list
                let newInactiveUsers = inactiveUsers.data.filter(user => user.userID !== postdata.userID);
                setInactiveUsers({
                    data: newInactiveUsers,
                    loaded: true
                });
            } else {
                toast.error("Something went wrong");
            }
        });
    }

    function closeModal() {
        setShowModal(false);
        setFormFields({});
    }

    const theme = window.sessionStorage.getItem("theme");

    const columns = [
        {
            dataIndex: 'fName',
            title: 'First Name'
        },
        {
            dataIndex: 'lName',
            title: 'Last Name'
        },
        {
            dataIndex: 'email',
            title: 'Email Address'
        },
        {
            title: 'Activate User',
            render: (text, record) => (
                <button className="btn alert-info" onClick={() => openActivateUser(record)}>Activate User</button>
            )
        }
    ];

    return (
        <SlidingPane
            overlayClassName='pinkCard'
            className={theme === 'dark' ? "dark-theme" : 'light-theme'}
            isOpen={showPane}
            title='Inactive Users'
            onRequestClose={hidePane}
            shouldCloseOnEsc={false}
        >

            <div className="slide-pane-body h-100">
                {inactiveUsers.loaded &&
                    <Table
                        rowKey={(record) => record.userID}
                        dataSource={inactiveUsers.data}
                        columns={columns}
                        size='small'
                    />
                }
            </div>

            <Modal
                title={`Activate ${user ? user.fName : ''} ${user ? user.lName : ''}`}
                visible={showModal}
                onCancel={closeModal}
                onOk={handleSubmit}
            >
                <div className="row">
                    {errorMsg &&
                        <div className="row">
                            <div className="col-12">
                                <p className="text-danger">** {errorMsg}</p>
                            </div>
                        </div>
                    }
                    <div className="form-group col-12">
                        <label className="form-label">Date Trained <span className="color-pink">*</span></label>
                        <DatePicker
                            selected={formFields.date ? Moment(formFields.date).utc().toDate() : null}
                            onChange={(date) => setFormFields({...formFields, date})}
                            className="form-control-custom"
                            showMonthDropdown
                            showYearDropdown
                            dropdownMode="select"
                            customInput={
                                <NumberFormat format="##/##/####" mask="_" />
                            }
                            maxDate={new Date()}
                        />
                    </div>

                    <div className="form-group col-12">
                        <label className="form-label">Trained By <span className="color-pink">*</span></label>
                        <Select
                            allowClear
                            placeholder="Please select"
                            onChange={(value) => setFormFields({ ...formFields, userID: value })}
                            className="form-control-custom w-100"
                            bordered={false}
                            value={formFields.userID || ''}
                            options={activeUsers.map(user => ({ label: user.fName + ' ' + user.lName, value: user.userID }))}
                            filterOption={(input, option) =>
                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            showSearch
                        />
                    </div>
                </div>
            </Modal>
        </SlidingPane>
    );
    
}

export default withRouter(ViewInactiveUsers);